/* eslint-disable prettier/prettier */
import React from "react";
import {Col, Form, Input, Row} from "antd";
import styled from "styled-components";
import logoOpened from "../../../../assets/images/onex_logo_open.svg";
import background from "../../../../assets/images/login-background.png";

import {getRequiredRule,} from "../../../../helpers/validation";

import styles from "./loginForm.module.css";
import ButtonPrimary from "../../../moleculs/ButtonPrimary";

const StyledLogin = styled.div`
  max-height: 100vh;
  height: 100vh;

  .left-bar {
    padding: 100px 85px 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    p {
      color: #8e9ba7;
      font-size: 12px;
    }

    h1 {
      color: #5dba2f;
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      line-height: 20px;
    }

    h2 {
      color: #262626;
      font-size: 30px;
      font-weight: 500;
    }

    .ant-form-item-label {
      padding-bottom: 2px;

      label {
        color: #3b5166;
        font-size: 14px;

        &:before {
          display: none;
        }
      }
    }

    button {
      display: block;
      width: 100%;
      padding: 10px 16px;
    }

    input {
      padding: 10px 11px !important;
    }
  }

  .right-bar {
    background: #f5f5f5;
    height: 100vh;
    position: relative;

    img {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
`;

function LoginForm({onError, onFail}) {
    return (
        <StyledLogin>
            <Row>
                <Col lg={10}>
                    <div className="left-bar">
                        <img src={logoOpened} alt="" style={{width: 150}}/>
                        <Form
                            className={styles.form_wrapper}
                            name="login-form"
                            onFinish={onFail}
                            layout="vertical"
                            onFinishFailed={onError}
                        >
                            <h1>Log in to your account</h1>
                            <h2>Please enter your details.</h2>
                            <Form.Item name="email" label="Email" rules={[getRequiredRule()]}>
                                <Input placeholder="Enter your email"/>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required",
                                    },
                                    {
                                        min: 8,
                                        message: "Minimum 8 characters",
                                    },
                                ]}
                                label="Password"
                            >
                                <Input.Password placeholder="Enter your password"/>
                            </Form.Item>
                            <Form.Item>
                                <ButtonPrimary text="Sign In" htmlType="submit"/>
                            </Form.Item>
                        </Form>
                        <p>Welcome to your dashboard</p>
                    </div>
                </Col>
                <Col lg={14}>
                    <div
                        className="right-bar"
                        style={{backgroundImage: `url(${background})`}}
                    ></div>
                </Col>
            </Row>
        </StyledLogin>
    );
}

export default LoginForm;
