import { useMemo } from "react";

const perm = localStorage.getItem("permissions") || {};
console.log(JSON.parse(perm), "perm111");
// permissions : {
//   pages:{
//        permission_name: boolean
//    }
//   permission_name_1: boolean,
//   permission_name_2: boolean,
//  }
export const usePermission = () => {
  const permission_bool = useMemo(() => {
    if (!perm) return {};
    else {
      let obj = {
        pages: {},
      };
      const parsed = JSON.parse(perm);
      if (!parsed && !parsed?.length) return {};
      const perm_array = Object.values(parsed);
      if (!perm_array?.length) return obj;
      for (let value of perm_array.flat()) {
        if (value.group === "pages") {
          obj.pages[value?.name?.split("-").join("_")] = true;
        } else {
          obj[value?.name?.split("-").join("_")] = true;
        }
      }

      return obj;
    }
  }, [perm]);

  return permission_bool;
};
