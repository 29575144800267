import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppRoutes } from "./components/meta/App.routes";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#5dba2f",
            colorText: "#3B5166",
          },
        }}
      >
        <AppRoutes />
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
