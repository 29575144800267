import styled from "styled-components";
import { createElement } from "react";

const StyledH4 = styled.span`
  .typography {
    font-weight: ${({ weight }) => weight || "normal"};
    font-size: ${({ size }) => size || "16px"};
    line-height: ${({ lineHeight }) => lineHeight || "21px"};
    background: ${({ backgroundTd }) => backgroundTd || "transparent"};
    font-family: "Montserrat arm2";
    color: ${({ color }) => color || "#262626"};
    margin: ${({ margin }) => margin || "0"};
    min-width: ${({ minWidth }) => minWidth || "auto"};
    display: ${({ display }) => display || "block"};
    text-align: ${({ align }) => align || "left"};
    text-transform: ${({ transform }) => transform || "unset"};
    padding: ${({ padding }) => padding};
    @media (max-width: 768px) {
      font-size: ${({ xsSize }) => xsSize || "16px"} !important;
      padding: ${({ xsPadding }) => xsPadding};
    }
    //display: block;
  }
`;

function Typography({ variant = "p", text, className = "", ...props }) {
  return (
    <StyledH4 {...props}>
      {createElement(variant, { className: `typography  ${className}` }, text)}
    </StyledH4>
  );
}

export default Typography;
