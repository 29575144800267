import {Navigate, Route, Routes} from "react-router-dom";
import {OnexAppRoutes} from "../../../constants/routes.constants";
import {lazy, Suspense} from "react";
import Sidebar from "../Sidebar";
import styled from "styled-components";
import {usePermission} from "../../../hooks/permissionFinder.hook.js";
import Loader from "../../moleculs/Loader.jsx";

// Lazy load components
const Orders = lazy(() => import("../../../pages/Orders/Orders"));
const Home = lazy(() => import("../../../pages/Home"));
const CustomerSingle = lazy(() => import("../../../pages/SingleCustomer"));
const Balance = lazy(() => import("../../../pages/Balance/Index"));
const Scan = lazy(() => import("../../../pages/Scan"));
const ScanSingle = lazy(() => import("../../../pages/Scan/Single"));
const Consignment = lazy(() => import("../../../pages/Consignment"));
const Expected = lazy(() => import("../../../pages/Expected"));
const OrdersCheck = lazy(() => import("../../../pages/Orders/Check"));
const Delivery = lazy(() => import("../../../pages/Delivery/Index"));
const Customers = lazy(() => import("../../../pages/Customers/Index"));
const InLocalCountry = lazy(() => import("../../../pages/InLocalCountry"));
const DownloadExcel = lazy(() => import("../../../pages/DownloadExcel.jsx"));
const ReceiveOrders = lazy(() =>
  import("../../../pages/ReceiveOrders/index.jsx")
);
const Dashboard = lazy(() => import("../../../pages/Dashboard"));
const Members = lazy(() => import("../../../pages/MembersAndRoles/index.jsx"));
const AppVersions = lazy(() => import("../../../pages/AppVersions"));
const Slides = lazy(() => import("../../../pages/Slides/index.jsx"));
const Blog = lazy(() => import("../../../pages/Blog/index.jsx"));
const Reports = lazy(() => import("../../../pages/Reports"));
const Banners = lazy(() => import("../../../pages/Banners"));
const Bonus = lazy(() => import("../../../pages/Bonus/Index.jsx"));
const Shops = lazy(() => import("../../../pages/Shops/index.jsx"));
const Notifications = lazy(() => import("../../../pages/Notifications"));
const OrdersFailed = lazy(() =>
  import("../../../pages/OrdersFailed/index.jsx")
);
const Stories = lazy(() => import("../../../pages/Story"));
const Roles = lazy(() => import("../../../pages/MembersAndRoles/Roles"));
const ActionsHistory = lazy(() => import("../../../pages/ActionsHistory"));
const OnlinePayment = lazy(() =>
  import("../../../pages/OnlinePayment/Index.jsx")
);
const PrimeUsers = lazy(() => import("../../../pages/PrimeUsers"));
const TestUsers = lazy(() => import("../../../pages/TestUsers/index.jsx"));
const Tariffs = lazy(() => import("../../../pages/Tariffs/index.jsx"));
const LostOrders = lazy(() => import("../../../pages/LostOrders/index.jsx"));
const CustomizedPricing = lazy(() =>
  import("../../../pages/CustomizedPricing/index.jsx")
);

const StyledLayout = styled.div`
  .content {
    padding-bottom: 24px;
    width: 100%;
    margin-left: 100px;
    margin-right: 20px;
    @media (max-width: 1400px) {
      width: 90%;
    }
    @media (max-width: 1135px) {
      width: 88%;
    }
    @media (max-width: 991px) {
      width: 85%;
    }
    @media (max-width: 768px) {
      width: calc(100% - 48px);
      margin-left: 24px;
      margin-right: 24px;
    }
  }
`;

export default function AuthenticatedApp() {
  const { pages } = usePermission();

  const routes = [
    {
      path: OnexAppRoutes.home(),
      component: <Home />,
      permission: pages?.home,
    },
    {
      path: OnexAppRoutes.orders(),
      component: <Orders />,
      permission: pages?.orders,
    },
    {
      path: OnexAppRoutes.customers(),
      component: <Customers />,
      permission: pages?.customers,
    },
    {
      path: OnexAppRoutes.balance(),
      component: <Balance />,
      permission: pages?.balance,
    },
    {
      path: OnexAppRoutes.bonus(),
      component: <Bonus />,
      permission: pages?.bonus,
    },
    {
      path: OnexAppRoutes.onlinePayment(),
      component: <OnlinePayment />,
      permission: pages?.online_payment,
    },
    // {
    //   path: OnexAppRoutes.scan(),
    //   component: <Scan />,
    //   permission: pages?.scan,
    // },
    // {
    //   path: OnexAppRoutes.scanSingle(),
    //   component: <ScanSingle />,
    //   permission: pages?.scan,
    // },
    {
      path: OnexAppRoutes.consignment(),
      component: <Consignment />,
      permission: pages?.consignment,
    },
    {
      path: OnexAppRoutes.expected(),
      component: <Expected />,
      permission: pages?.expected,
    },
    {
      path: OnexAppRoutes.ordersCheck(),
      component: <OrdersCheck />,
      permission: pages?.orders_check,
    },
    {
      path: OnexAppRoutes.ordersFailed(),
      component: <OrdersFailed />,
      permission: pages?.orders_failed,
    },
    {
      path: OnexAppRoutes.delivery(),
      component: <Delivery />,
      permission: pages?.delivery,
    },
    // {
    //   path: OnexAppRoutes.inLocalCountry(),
    //   component: <InLocalCountry />,
    //   permission: pages?.in_local_country,
    // },
    {
      path: OnexAppRoutes.dashboard(),
      component: <Dashboard />,
      permission: pages?.dashboard,
    },
    {
      path: OnexAppRoutes.downloadExcel(),
      component: <DownloadExcel />,
      permission: true,
    }, // Assume no permission required
    {
      path: OnexAppRoutes.receiveOrders(),
      component: <ReceiveOrders />,
      permission: pages?.receive_orders,
    },
    {
      path: OnexAppRoutes.customerSingle(),
      component: <CustomerSingle />,
      permission: pages?.customers,
    },
    {
      path: OnexAppRoutes.membersAndRoles(),
      component: <Members />,
      permission: pages?.members,
    },
    {
      path: OnexAppRoutes.slides(),
      component: <Slides />,
      permission: pages?.slides,
    },
    {
      path: OnexAppRoutes.blog(),
      component: <Blog />,
      permission: pages?.blog,
    },
    {
      path: OnexAppRoutes.banners(),
      component: <Banners />,
      permission: pages?.banners,
    },
    {
      path: OnexAppRoutes.shops(),
      component: <Shops />,
      permission: pages?.shops,
    },
    {
      path: OnexAppRoutes.reports(),
      component: <Reports />,
      permission: pages?.reports,
    },
    {
      path: OnexAppRoutes.notifications(),
      component: <Notifications />,
      permission: pages?.notifications,
    },
    {
      path: OnexAppRoutes.stories(),
      component: <Stories />,
      permission: pages?.stories,
    },
    {
      path: OnexAppRoutes.roles(),
      component: <Roles />,
      permission: pages?.roles,
    },
    {
      path: OnexAppRoutes.actionsHistory(),
      component: <ActionsHistory />,
      permission: pages?.actions_history,
    },
    {
      path: OnexAppRoutes.lost_orders(),
      component: <LostOrders />,
      permission: pages?.lost_orders,
    },
    {
      path: OnexAppRoutes.prime_users(),
      component: <PrimeUsers />,
      permission: pages?.prime_users,
    },
    {
      path: OnexAppRoutes.test_users(),
      component: <TestUsers />,
      permission: pages?.test_users,
    },
    {
      path: OnexAppRoutes.tariffs(),
      component: <Tariffs />,
      permission: pages?.tariffs1126,
    },
    {
      path: OnexAppRoutes.customized_pricing(),
      component: <CustomizedPricing />,
      permission: pages?.customized_pricing1126,
    },
    {
      path: OnexAppRoutes.app_versions(),
      component: <AppVersions />,
      permission: pages?.app_versions,
    },
  ];

  return (
    <StyledLayout>
      <Sidebar>
        <Suspense fallback={<Loader />}>
          <Routes>
            {routes.map((route, index) => {
              const { path, component, permission } = route;
              return (
                <Route
                  key={index}
                  path={path}
                  element={permission ? component : <Navigate to="/" />}
                />
              );
            })}
          </Routes>
        </Suspense>
      </Sidebar>
    </StyledLayout>
  );
}
