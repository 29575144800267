import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { items } from "../../constants/sidebar-data.jsx";
import { usePermission } from "../../hooks/permissionFinder.hook.js";

export const StyledSidebar = styled.div`
  display: flex;

  .content {
    min-height: 100vh;
    width: 100%;
    background: #f9fafb;
  }

  .ant-menu li:nth-last-child(2) {
    margin: auto 0 0 0;
  }

  .ant-menu li:first-child {
    position: absolute;
    top: 20px;
  }

  .ant-menu li:nth-child(2) {
    margin: 20px 0 0 0;
  }

  ._user-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #5dba2f;
  }

  .ant-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .ant-menu-item {
      display: inline-grid;
      align-items: self-end;
      justify-content: center;
      width: max-content;
      height: 50px;
    }

    .ant-menu-submenu-title {
      padding-inline-end: 14px;
      display: inline-grid;
      align-items: self-end;
      justify-content: center;
      width: max-content;
      height: 50px;
    }

    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        background-color: #f2faeb;
      }
    }

    .ant-menu-submenu-arrow {
      display: none;
    }
  }
`;

export default function Sidebar({ opened, setOpened, children }) {
  const { pages } = usePermission();

  const navigate = useNavigate();
  const channelAuth = new BroadcastChannel("auth", {
    type: "localstorage",
    webWorkerSupport: true,
  });
  const onClick = (e) => {
    if (e.key === "user") {
      channelAuth.postMessage(null);
      localStorage.clear();
      navigate("/");
    } else {
      navigate(e.key);
    }
  };
  return (
    <StyledSidebar opened={opened} className={"slider-wrapper"}>
      <Menu
        onClick={onClick}
        style={{
          width: 80,
          minHeight: "100vh",
          paddingTop: "70px",
          top: 0,
          bottom: 0,
          position: "fixed",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
        mode="vertical"
        items={items(pages)}
      />
      <div className={"content"}>{children}</div>
    </StyledSidebar>
  );
}
