import dashboardIcon from "../assets/images/dashboard.svg";
import Flex from "../components/general/Flex.jsx";
import Typography from "../components/moleculs/Typography.jsx";
import users_icon from "../assets/images/users-icon.svg";
import prime_users_icon from "../assets/images/prime_users.svg";
import layers_icon from "../assets/images/layers-icon.svg";
import expected_icon from "../assets/images/expected-menu-icon.svg";
import orders_check_icon from "../assets/images/orders-check.svg";
import orders_filed_icon from "../assets/images/failed-orders.svg";
import finance_icon from "../assets/images/finance-icon.svg";
import balance_icon from "../assets/images/balance-icon.svg";
import reports_icon from "../assets/images/reports-icon.svg";
import payment_icon from "../assets/images/payment-icon.svg";
import bonus_icon from "../assets/images/bonus-tab-icon.svg";
import consignment_icon from "../assets/images/consignment.svg";
import receive_orders_icon from "../assets/images/receive-orders.svg";
import members_icon from "../assets/images/members-and-roles.svg";
import roles_icon from "../assets/images/roles-icon.svg";
import actions_history_icon from "../assets/images/actions_history.svg";
import content_icon from "../assets/images/content.svg";
import banner_icon from "../assets/images/banner.svg";
import blog_icon from "../assets/images/blog.svg";
import shops_icon from "../assets/images/shops.svg";
import warehouses_icon from "../assets/images/warehouses.svg";
import story_icon from "../assets/images/story.svg";
import notification_icon from "../assets/images/notification-box.svg";
import best_offers_icon from "../assets/images/best_offers.svg";
import settings_icon from "../assets/images/settings-icon.svg";
import logout_icon from "../assets/images/log-out-icon.svg";
import customized_pricing_icon from "../assets/images/customized-pricing-icon.svg";
import tariffs_icon from "../assets/images/tariffs-icon.svg";
import onex_logo from "../assets/images/onex_logo_closed.svg";
import app_versions from "../assets/images/api-versions.svg";
import lostIcon from "../assets/images/box-remove.svg";

import { Tag } from "antd";
import { Link } from "react-router-dom";

function getItem(label, key = "", icon, children, type, component) {
  return {
    key,
    icon,
    children,
    label: (
      <Link
        to={key && key !== "user" ? `/${encodeURIComponent(key)}` : "/"}
        onClick={() => {
          if (key === "user") {
            localStorage.clear();
          }
        }}
      >
        {label}
      </Link>
    ),
    type,
    component,
  };
}

export const items = (pages) => [
  getItem(
    "",
    "/",
    <div className={"home_logo"}>
      <img alt={"home"} src={onex_logo} />
    </div>
  ),
  pages?.dashboard
    ? getItem("", "dash", <img alt={"dashboard"} src={dashboardIcon} />, [
        getItem(
          "Dashboard",

          "dashboard",
          <img alt={"dashboard"} src={dashboardIcon} />
        ),
      ])
    : null,
  pages?.customers || pages?.prime_users || pages?.test_users
    ? getItem("", "rec", <img alt={"recipients"} src={users_icon} />, [
        pages?.customers
          ? getItem(
              "Customers",
              "customers",
              <img alt={"users"} src={users_icon} />
            )
          : null,
        pages?.prime_users
          ? getItem(
              "Prime Users",
              "prime-users",
              <img alt={"prime_users"} src={prime_users_icon} />
            )
          : null,
        pages?.test_users
          ? getItem(
              "Test Users",
              "test-users",
              <img alt={"test_users"} src={prime_users_icon} />
            )
          : null,
      ])
    : null,
  pages?.orders ||
  pages?.expected ||
  pages?.orders_check ||
  pages?.orders_failed
    ? getItem("", "ord", <img alt={"orders"} src={layers_icon} />, [
        pages?.expected
          ? getItem(
              "Expected",
              "expected",
              <img alt={"order"} src={expected_icon} />
            )
          : null,
        pages?.orders
          ? getItem(
              "Orders",
              "orders",
              <img alt={"orders"} src={layers_icon} />
            )
          : null,
        pages?.orders_check
          ? getItem(
              "Orders check",
              "orders-check",
              <img alt={"orders_check"} src={orders_check_icon} />
            )
          : null,
        pages?.orders_failed
          ? getItem(
              "Failed orders",
              "orders-failed",
              <img alt={"failed_orders"} src={orders_filed_icon} />
            )
          : null,
      ])
    : null,
  pages?.lost_orders
    ? getItem("", "lost", <img alt={"lost_order"} src={lostIcon} />, [
        getItem(
          "Lost orders",
          "lost-orders",
          <img alt={"lost_order"} src={lostIcon} />
        ),
      ])
    : null,
  pages?.tariffs1126 || pages?.customized_pricing1126
    ? getItem("", "tar", <img alt={"tariffs"} src={tariffs_icon} />, [
        pages?.tariffs
          ? getItem(
              "Tariffs",
              "tariffs",
              <img alt={"tariffs"} src={tariffs_icon} />
            )
          : null,
        pages?.customized_pricing
          ? getItem(
              "Customized pricing",
              "customized-pricing",
              <img alt={"pricing"} src={customized_pricing_icon} />
            )
          : null,
      ])
    : null,
  pages?.balance || pages?.reports || pages?.online_payment || pages?.reports
    ? getItem("", "bal", <img alt={"bal_bon_rep"} src={finance_icon} />, [
        pages?.balance
          ? getItem(
              "Balance",
              "balance",
              <img alt={"balance"} src={balance_icon} />
            )
          : null,
        pages?.reports
          ? getItem(
              "Reports",
              "reports",
              <img alt={"reports"} src={reports_icon} />
            )
          : null,
        pages?.online_payment
          ? getItem(
              "Online payment",
              "online-payment",
              <img alt={"online_payment"} src={payment_icon} />
            )
          : null,
        pages?.bonus
          ? getItem("Bonus", "bonus", <img alt={"bonus"} src={bonus_icon} />)
          : null,
      ])
    : null,
  pages?.consignment
    ? getItem("", "par", <img alt={"parcel"} src={consignment_icon} />, [
        getItem(
          "Consignment",
          "consignment",
          <img alt={"consignment"} src={consignment_icon} />
        ),
      ])
    : null,
  pages?.receive_orders
    ? getItem(
        "",
        "rec_ord",
        <img alt={"rec_ord"} src={receive_orders_icon} />,
        [
          getItem(
            "Receive orders",
            "receive-orders",
            <img alt={"consignment"} src={receive_orders_icon} />
          ),
        ]
      )
    : null,
  pages?.members || pages?.roles
    ? getItem("", "role", <img alt={"role"} src={members_icon} />, [
        pages?.members
          ? getItem(
              "Members",
              "members",
              <img alt={"members"} src={members_icon} />
            )
          : null,
        pages?.roles
          ? getItem("Roles", "roles", <img alt={"roles"} src={roles_icon} />)
          : null,
      ])
    : null,
  pages?.actions_history
    ? getItem("", "act", <img alt={"action"} src={actions_history_icon} />, [
        getItem(
          "Actions history",
          "actions-history",
          <img alt={"action"} src={actions_history_icon} />
        ),
      ])
    : null,
  pages?.banners ||
  pages?.notifications ||
  pages?.slides ||
  pages?.blog ||
  pages?.warehouses ||
  pages?.stories ||
  pages?.stories ||
  pages?.best_offers
    ? getItem("", "content", <img alt={"slides"} src={content_icon} />, [
        pages?.banners
          ? getItem(
              "Header",
              "banners",
              <img alt={"header"} src={banner_icon} />
            )
          : null,
        pages?.slides
          ? getItem(
              "Sliders",
              "slides",
              <img alt={"slides"} src={content_icon} />
            )
          : null,
        pages?.blog
          ? getItem("Blogs", "blog", <img alt={"blogs"} src={blog_icon} />)
          : null,
        pages?.shops
          ? getItem("Shops", "shops", <img alt={"shops"} src={shops_icon} />)
          : null,
        pages?.warehouses
          ? getItem(
              <>
                Warehouses
                <Tag color={"red"} style={{ margin: "10px 0 0 10px" }}>
                  Not ready
                </Tag>
              </>,
              "warehouses",
              <img alt={"warehouses"} src={warehouses_icon} />
            )
          : null,
        pages?.stories
          ? getItem(
              <>
                Stories
                <Tag color={"red"} style={{ margin: "10px 0 0 10px" }}>
                  Not ready
                </Tag>
              </>,
              "stories",
              <img alt={"story"} src={story_icon} />
            )
          : null,
        pages?.notifications
          ? getItem(
              <>
                Notifications
                <Tag color={"orange"} style={{ margin: "10px 0 0 10px" }}>
                  In progress
                </Tag>
              </>,
              "notifications",
              <img alt={"notification"} src={notification_icon} />
            )
          : null,
        pages?.best_offers
          ? getItem(
              <>
                Best offers
                <Tag color={"red"} style={{ margin: "10px 0 0 10px" }}>
                  Not ready
                </Tag>
              </>,
              "best_offers",
              <img alt={"notification"} src={best_offers_icon} />
            )
          : null,
      ])
    : null,

  pages?.app_versions
    ? getItem("", "app", <img alt={"slides"} src={app_versions} />, [
        getItem(
          "App Version",
          "app-versions",
          <img alt={"app_versions"} src={app_versions} />
        ),
      ])
    : null,

  getItem("", "sett", <img alt={"action"} src={settings_icon} />, [
    getItem(
      "Settings",
      "settings",
      <img alt={"settings"} src={settings_icon} />
    ),
  ]),

  getItem("", "usr", <div className={"_user-avatar"} />, [
    getItem(
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <div>
          <Typography
            text={localStorage.getItem("name") || ""}
            fontWeight={"500"}
            variant="span"
            size="14px"
            padding="0 0 0 0"
            color="rgba(59, 81, 102, 1)"
            weight="400"
          />
          <Typography
            text={localStorage.getItem("email") || ""}
            fontWeight={"500"}
            variant="span"
            size="14px"
            padding="0 15px 0 0"
            color="rgba(59, 81, 102, 1)"
            weight="400"
          />
        </div>
        <img alt={"logout"} src={logout_icon} />
      </Flex>,
      "user"
    ),
  ]),
];
